var controller;
$(document).ready(function() {

	if(!$("html").hasClass("no-animations")){

		controller = new ScrollMagic.Controller(),
			animatedBlocks = ".aa-animated";

			$(animatedBlocks).each(function(){
				var currentBlock = $(this),
					delayCutFrom = 0.5, 			// Di quanto è anticipata l'animazione seguente rispetto all'andamento "normale" della timeline? (s)
					delayCutTo = 0.5, 			// Di quanto è anticipata l'animazione seguente rispetto all'andamento "normale" della timeline? (s)
					animationLength = 1,		// Tempo dell'animazione (s)
					lateralMovement = 70,		// Di quanti pixel sarà il movimento orizzontale dell'elemento (px)
					verticalMovement = 70,		// Di quanti pixel sarà il movimento verticale dell'elemento (px)
					triggerOffset = -50;			// Anticipo o posticipo dell'animazione rispetto alla posizione attuale del trigger (px)

				currentBlock.find(".aa-title, .aa-text, .aa-image").each(function(){
					var textAnimation = new TimelineLite();
					var currentTrigger=this;
					// Riscrivo i default se trovo dei valori data-*
					delayCutFrom 			= ($(this).data("delaycut-from") != undefined) ? $(this).data("delaycut-from") : delayCutFrom;
					delayCutTo 			= ($(this).data("delaycut-to") != undefined) ? $(this).data("delaycut-to") : delayCutTo;
					animationLength 	= ($(this).data("animationlength") != undefined) ? $(this).data("animationlength") : animationLength;
					lateralMovement 	= ($(this).data("lateralmovement") != undefined) ? $(this).data("lateralmovement") : lateralMovement;
					verticalMovement 	= ($(this).data("verticalmovement") != undefined) ? $(this).data("verticalmovement") : verticalMovement;
					triggerOffset 		= ($(this).data("triggeroffset") != undefined) ? $(this).data("triggeroffset") : -50;

					animationCount = 1;
					var fadeIn 	= $(this).hasClass("aa-fade-in") ? true : false;
					var LtR 	= $(this).hasClass("aa-L-to-R")  ? true : false;
					var RtL 	= $(this).hasClass("aa-R-to-L")  ? true : false;
					var TtB 	= $(this).hasClass("aa-T-to-B")  ? true : false;
					var BtT 	= $(this).hasClass("aa-B-to-T")  ? true : false;

					if(!fadeIn && !LtR && !RtL && !TtB && !BtT){
						fadeIn = true;
					}

					//Fadein animation
					if(fadeIn){
						textAnimation
							.from($(this), animationLength, {
								autoAlpha: 0,
								force3D: true,
								ease: Power2.easeOut
							}, "-="+(animationLength * animationCount + delayCutFrom))
							.to($(this), animationLength, {
								autoAlpha: 1
							}, "-="+(animationLength * animationCount + delayCutTo ));
						//animationCount++;
					}

					//Left to right animation
					if(LtR){
						textAnimation
							.from($(this), animationLength, {
								autoAlpha: 0,
								left: -lateralMovement,
								force3D: true,
								ease: Power2.easeOut
							}, "-="+(animationLength * animationCount + delayCutFrom))
							.to($(this), animationLength, {
								autoAlpha: 1
							}, "-="+( animationLength * animationCount + delayCutTo ));
						//animationCount++;
					}

					//Right to Left animation
					if(RtL){
						textAnimation
							.from($(this), animationLength, {
								autoAlpha: 0,
								left: lateralMovement,
								force3D: true,
								ease: Power2.easeOut
							}, "-="+(animationLength * animationCount + delayCutFrom))
							.to($(this), animationLength, {
								autoAlpha: 1
							}, "-="+(animationLength * animationCount + delayCutTo ));
						//animationCount++;
					}

					//Top to Bottom animation
					if(TtB){
						textAnimation
							.from($(this), animationLength, {
								autoAlpha: 0,
								top: -verticalMovement,
								force3D: true,
								ease: Power2.easeOut
							}, "-="+(animationLength * animationCount + delayCutFrom))
							.to($(this), animationLength, {
								autoAlpha: 1
							}, "-="+(animationLength * animationCount + delayCutTo ));
						//animationCount++;
					}

					//Bottom to Top animation
					if(BtT){
						textAnimation
							.from($(this), animationLength, {
								autoAlpha: 0,
								top: verticalMovement,
								force3D: true,
								ease: Power2.easeOut
							}, "-="+(animationLength * animationCount + delayCutFrom ))
							.to($(this), animationLength, {
								autoAlpha: 1
							}, "-="+(animationLength * animationCount + delayCutTo ));
						//animationCount++;
					}

					var textScene = new ScrollMagic.Scene({ triggerElement: currentTrigger, offset: triggerOffset, reverse: false, triggerHook: 0.9 })
						.addTo(controller)
						.setTween(textAnimation);
					/*textScene.addIndicators ({
						name:'test'+ $(this).attr('class')
					});*/

				});
			});
	}
});
